import { render, staticRenderFns } from "./FAwesomeMaker.vue?vue&type=template&id=5c664213&v-if=icon"
import script from "./FAwesomeMaker.vue?vue&type=script&lang=js"
export * from "./FAwesomeMaker.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports